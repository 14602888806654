import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { firestore } from 'firebase/app';

import { TachografComponent } from './../tachograf/tachograf.component';
import { PojazdyDataSource } from './pojazdy-datasource';
import { PojazdyService } from './../pojazdy.service';
import { Pojazd } from './../pojazd';
import { Tachograf } from './../tachograf';

@Component({
  selector: 'app-pojazdy',
  templateUrl: './pojazdy.component.html',
  styleUrls: ['./pojazdy.component.css']
})
export class PojazdyComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: PojazdyDataSource;
  displayedColumns = ['nazwa', 'timestamp', 'predkosc', 'kierowca1', 'status1', 'kierowca2', 'status2', 'ustawienia'];

  constructor(private pojazdy: PojazdyService, private dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new PojazdyDataSource(this.paginator, this.sort, this.pojazdy);
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter$.next({searchTerm: filterValue.trim().toLowerCase()});
  }
  
  dodajTachograf(pojazd: Pojazd){
    const data = new Date();
    const endTime = new Date(data.getFullYear(), data.getMonth(), data.getDate());
    const startTime = new Date(+endTime - (90 * 24 * 60 *60 * 1000));
    const tachograf = {
      ident: pojazd.ident,
      karta: pojazd.karta,
      nazwa: pojazd.nazwa,
      whatToRead: 1,
      startTime: firestore.Timestamp.fromDate(startTime),
      endTime: firestore.Timestamp.fromDate(endTime),
      aktywne: false, 
      timestamp: firestore.FieldValue.serverTimestamp(),
      status: 0, 
      statusInfo: 'START', 
      statusTimestamp: firestore.FieldValue.serverTimestamp(), 
      atr: null, 
      apduTacho: null,
      apduSmartCard: null,
      rozmiar: null,
      postep: null,
      plik: null,
      crc: null
    };
    
    this.dialog.open(TachografComponent, { data: tachograf });
  }
  
}
