import { Pipe, PipeTransform } from '@angular/core';

import { Tachograf } from './tachograf';

@Pipe({
  name: 'tachografPostep'
})
export class TachografPostepPipe implements PipeTransform {

  transform(value: Tachograf, args?: string): string {
    let postep = 0;
    if (!value.rozmiar){
      return `0 %`;
    } else {
      return `${(100 * (value.postep / value.rozmiar)).toFixed()} %`
    }
  }

}
