import { Pipe, PipeTransform } from '@angular/core';

import { Tachograf } from './tachograf';

@Pipe({
  name: 'tachografStatus'
})
export class TachografStatusPipe implements PipeTransform {

  private statusyTachografu:Map<number, string> = new Map(
    [
      [0, "Utworzony"], [1, "Weryfikacja"], [2, "ATR"], [3, "APDU"], [4, "Uwierzytelniono"], 
      [5, "Pobieranie danych"], [11, "Pobieranie pliku"], [15, "Zakończono"]])
  
  transform(value: Tachograf, args?: string): string {
    if (value.status >= 100){
      return "Błąd";
    }
    return this.statusyTachografu.get(value.status);
  }

}
