import { Pipe, PipeTransform } from '@angular/core';

import { Tachograf } from './tachograf';

@Pipe({
  name: 'tachografTyp'
})
export class TachografTypPipe implements PipeTransform {

  transform(value: Tachograf, args?: string): string {
    if ((value.whatToRead & 0x01) === 0b001){
      let extra = '';
      if ((value.whatToRead & 0b001000) === 0b001000){
        extra = extra + 'Z';
      }
      if ((value.whatToRead & 0b010000) === 0b010000){
        extra = extra + 'P';
      }
      if ((value.whatToRead & 0b100000) === 0b100000){
        extra = extra + 'D';
      }
      if (extra){
        return `Tachograf [${extra}]`;
      } else {
        return 'Tachograf';
      }
    } else if ((value.whatToRead & 0x02) === 0b010){
      return 'Karta 1';
    } else if ((value.whatToRead & 0x04) === 0b100){
      return 'Karta 2';
    }
  }

}
