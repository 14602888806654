import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TachografyComponent } from './tachografy/tachografy.component';
import { PojazdyComponent } from './pojazdy/pojazdy.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: TachografyComponent },
      { path: 'tachografy', component: TachografyComponent },
      { path: 'pojazdy', component: PojazdyComponent },
      { path: 'kierowcy', component: TachografyComponent }
  ]}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
