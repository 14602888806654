import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Uzytkownik } from './uzytkownik'
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  uzytkownik$: Observable<Uzytkownik>;
  authState: any = null;
  
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private notify: NotifyService) 
  { 
    this.uzytkownik$ = this.afAuth.authState.pipe(
      switchMap(uzytkownik => {
        if (uzytkownik) {
          return this.afs.doc<Uzytkownik>(`uzytkownicy/${uzytkownik.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
    this.afAuth.authState.subscribe(auth => {
      this.authState = auth
    });
  }
  
  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        this.notify.displayNotification("Zalogowano");
        this.router.navigate(['/']);
        return this.updateUserData(credential.user);
      })
      .catch(error => this.handleError(error));
  }
  
  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.notify.displayNotification("Wylogowano");
      this.router.navigate(['/login']);
    });
  }
  
  private updateUserData(uzytkownik) {
    
    const uzytkownikRef: AngularFirestoreDocument<Uzytkownik> = this.afs.doc(`uzytkownicy/${uzytkownik.uid}`);

    const data = { 
      uid: uzytkownik.uid, 
      email: uzytkownik.email
    } 

    return uzytkownikRef.set(data, { merge: true })
  }
  
  private handleError(error){
    this.notify.displayNotification(error);
  }
}
