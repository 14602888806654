import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Pojazd } from './pojazd'

@Injectable({
  providedIn: 'root'
})
export class PojazdyService {

  constructor(private afs: AngularFirestore, private auth: AuthService) { }
  
  data(): Observable<Pojazd[]> {
    return this.getPojazdy().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Pojazd;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
  
  getPojazdy(): AngularFirestoreCollection<Pojazd> {
    return this.afs.collection<Pojazd>('pojazdy', ref => ref.where('uid', 'array-contains', this.auth.authState.uid).orderBy('nazwa', 'asc'));
  }
  
}
