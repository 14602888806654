import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kartaDecode'
})
export class KartaDecodePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value === '00000000000000000000000000000000') {
      return 'Brak karty';
    } else if (value) {
      return this.hexDecode(value);
    } else {
      return 'Brak odczytu';
    }
  }

  hexDecode(value: string){
    const hexes = value.match(/.{2}/g) || [];
    let back = "";
    for(var i = 0; i < hexes.length; i++) {
        back += String.fromCharCode(parseInt(hexes[i], 16));
    }
    return back;
  }
  
}
