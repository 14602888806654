import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Timestamp } from '@firebase/firestore-types';

import { TachografComponent } from './../tachograf/tachograf.component';
import { TachografyDataSource } from './tachografy-datasource';
import { TachografyService } from './../tachografy.service';
import { Tachograf } from './../tachograf';

@Component({
  selector: 'app-tachografy',
  templateUrl: './tachografy.component.html',
  styleUrls: ['./tachografy.component.css']
})
export class TachografyComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TachografyDataSource;
  displayedColumns = ['aktywne', 'timestamp', 'status', 'statusTimestamp', 'postep', 'ident', 'nazwa', 'whatToRead', 'startTimestamp', 'endTimestamp', 'ustawienia'];
   
  constructor(private tachografy: TachografyService, private dialog: MatDialog) { }
  
  ngOnInit() {
    this.dataSource = new TachografyDataSource(this.paginator, this.sort, this.tachografy);
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter$.next({searchTerm: filterValue.trim().toLowerCase()});
  }

  resetujTachograf(tachograf: Tachograf){
    this.tachografy.resetuj(tachograf);
  }
  
  edytujTachograf(tachograf: Tachograf){
    this.dialog.open(TachografComponent, { data: tachograf });
  }
}
