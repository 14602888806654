import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';

import { Tachograf } from './tachograf';

@Pipe({
  name: 'tachografPlik'
})
export class TachografPlikPipe implements PipeTransform {
  
  constructor(private storage: AngularFireStorage) { }

  transform(value: Tachograf, args?: string): Observable<string | null> {
    if (value.aktywne === false && value.status === 15 && value.plik){
      return this.storage.ref(value.plik).getDownloadURL();
    }
    return null;
  }

}
