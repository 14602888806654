import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Tachograf } from './tachograf'

@Injectable({
  providedIn: 'root'
})
export class TachografyService {
  
  uid: string;
  
  constructor(private afs: AngularFirestore, private auth: AuthService) { }
  
  data(): Observable<Tachograf[]> {
    return this.getTachografy().snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Tachograf;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
  
  getTachografy(): AngularFirestoreCollection<Tachograf> {
    return this.afs.collection<Tachograf>('tachografy', ref => ref.where('uid', 'array-contains', this.auth.authState.uid).orderBy('timestamp', 'desc'));
  }
  
  resetuj(tachograf: Tachograf) : void {
    
    const stan = {
      aktywne: false, 
      status: 0, 
      statusInfo: 'START', 
      statusTimestamp: firestore.FieldValue.serverTimestamp(), 
      atr: null, 
      apduTacho: null,
      apduSmartCard: null,
      rozmiar: null,
      postep: null,
      plik: null,
      crc: null
    };
    
    this.afs.doc(`tachografy/${tachograf.id}`).update(stan);
  }
  
  zapisz(tachograf: Tachograf, stan: any) : void {
    this.afs.doc(`tachografy/${tachograf.id}`).update(stan);
  }
  
  dodaj(tachograf: Tachograf) : void {
    tachograf.uid = [this.auth.authState.uid];
    this.afs.collection<Tachograf>(`tachografy`).add(tachograf);
  }
}
