import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aktywnosc'
})
export class AktywnoscPipe implements PipeTransform {

  private aktywnosc:Map<number, string> = new Map(
    [
      [0, 'Odpoczynek'], [1, 'Dostępny'], [2, 'Praca'], [3, 'Jazda'], [6, 'Błąd'], [7, 'Brak danych']])
      
  transform(value: number, args?: any): string {
    if (value !== undefined){
      return this.aktywnosc.get(value);
    } else {
      return 'Brak odczytu';
    }
  }

}
