import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AuthService } from './../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  
  loginForm = this.fb.group({
    uzytkownik: [null, Validators.required],
    haslo: [null, Validators.required]
  });

  constructor(private fb: FormBuilder, private auth: AuthService) {}

  onSubmit() {
    this.auth.emailLogin(this.loginForm.value.uzytkownik, this.loginForm.value.haslo);
  }
}
