import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable, BehaviorSubject, of as observableOf, merge, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { PojazdyService } from './../pojazdy.service';
import { Pojazd } from './../pojazd'

export class PojazdyDataSource extends DataSource<Pojazd> {
  
  filter$ = new BehaviorSubject({searchTerm: ''});
  
  filter = null;
  data: Pojazd[] = [];
  
  constructor(private paginator: MatPaginator, private sort: MatSort, private pojazdy: PojazdyService) {
    super();
    this.filter$.subscribe(filter => this.filter = filter.searchTerm);
    this.pojazdy.data().subscribe(data => this.data = data);
  }

  connect(): Observable<Pojazd[]> {
    
    const dataMutations = [
      this.pojazdy.data(),
      this.paginator.page,
      this.sort.sortChange,
      this.filter$
    ];
    
    return merge(...dataMutations).pipe(map(() => {
      
      const filteredData = this.getFilteredData([...this.data]);
      this.paginator.length = filteredData.length;
      
      return this.getPagedData(this.getSortedData(filteredData));
    }));
  }

  disconnect() {}

  private getFilteredData(data: Pojazd[]) {
    return data.filter(data => {
      return data['nazwa'].toLocaleLowerCase().includes(this.filter.toLocaleLowerCase());
    });
  }
  
  private getPagedData(data: Pojazd[]) {
    let startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    if (startIndex > data.length) {
      this.paginator.pageIndex = Math.floor(data.length / this.paginator.pageSize);
      startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    }
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSortedData(data: Pojazd[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'nazwa': return compare(a.nazwa, b.nazwa, isAsc);
        case 'ident': return compare(+a.ident, +b.ident, isAsc);
        default: return 0;
      }
    });
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
