import { Pipe, PipeTransform } from '@angular/core';

import { Tachograf } from './tachograf';

@Pipe({
  name: 'tachografAktywne'
})
export class TachografAktywnePipe implements PipeTransform {

  transform(value: Tachograf, args?: string): string {
    if (value.aktywne){
      return 'Tak';
    }
    return 'Nie';
  }

}
