import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { MatCardModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatRadioModule, MatMenuModule, MatSnackBarModule, MatProgressSpinnerModule } from '@angular/material';
import { MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule } from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { TachografyComponent } from './tachografy/tachografy.component';
import { TachografComponent } from './tachograf/tachograf.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';

import { environment } from '../environments/environment';
import { TachografTypPipe } from './tachograf-typ.pipe';
import { TachografPostepPipe } from './tachograf-postep.pipe';
import { TachografStatusPipe } from './tachograf-status.pipe';
import { TachografAktywnePipe } from './tachograf-aktywne.pipe';
import { TachografPlikPipe } from './tachograf-plik.pipe';
import { PojazdyComponent } from './pojazdy/pojazdy.component';
import { KartaDecodePipe } from './karta-decode.pipe';
import { AktywnoscPipe } from './aktywnosc.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    TachografyComponent,
    TachografComponent,
    TachografTypPipe,
    TachografPostepPipe,
    TachografStatusPipe,
    TachografAktywnePipe,
    TachografPlikPipe,
    PojazdyComponent,
    KartaDecodePipe,
    AktywnoscPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule, 
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule, 
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  entryComponents: [
    TachografComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pl-PL' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
