import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

export interface Wiadomosc {
  tresc: string;
  styl: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private snackBar: MatSnackBar) { }
    
  public displayNotification(wiadomosc): void {
      const config: MatSnackBarConfig = new MatSnackBarConfig();
      config.duration = 2500;

      this.snackBar.open(wiadomosc, "Zamknij", config);
  }
    
}
