import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA , MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, ValidatorFn, FormGroup } from '@angular/forms';
import { firestore } from 'firebase/app'

import { TachografyService } from './../tachografy.service';
import { Tachograf } from './../tachograf';


const TachografCzasValidator: ValidatorFn = (fg: FormGroup) => {
  const start = new Date(fg.get('startCzas').value);
  const end = new Date(fg.get('koniecCzas').value);
  
  return start !== null && end !== null && start.getTime() < end.getTime()
    ? null
    : { tachografCzas: true };
};

@Component({
  selector: 'app-tachograf',
  templateUrl: './tachograf.component.html',
  styleUrls: ['./tachograf.component.css']
})
export class TachografComponent {

  tachografForm = this.fb.group({
    nazwa: [null, Validators.required],
    typ: [null, Validators.required],
    zdarzenia: [null],
    predkosc: [null],
    dane: [null],
    startCzas: [{value: null, disabled: true}, Validators.required],
    koniecCzas: [{value: null, disabled: true}, Validators.required]
    }, { validators: TachografCzasValidator });
  
  constructor(
    private tachografy: TachografyService, 
    @Inject(MAT_DIALOG_DATA) private tachograf: Tachograf, 
    private dialogRef: MatDialogRef<TachografComponent>,
    private fb: FormBuilder) {
    
    const typ = tachograf.whatToRead & 0b000111;
    this.tachografForm.controls['nazwa'].setValue(tachograf.nazwa);
    this.tachografForm.controls['typ'].setValue(typ.toString());
    this.tachografForm.controls['zdarzenia'].setValue((tachograf.whatToRead & 0b001000) === 0b001000);
    this.tachografForm.controls['predkosc'].setValue((tachograf.whatToRead & 0b010000) === 0b010000);
    this.tachografForm.controls['dane'].setValue((tachograf.whatToRead & 0b100000) === 0b100000);
    this.tachografForm.controls['startCzas'].setValue(tachograf.startTime.toDate());
    this.tachografForm.controls['koniecCzas'].setValue(tachograf.endTime.toDate());
  }

  onSubmit() {
    let whatToRead = +this.tachografForm.value.typ;
    if (this.tachografForm.value.zdarzenia) {
      whatToRead = whatToRead | 0b001000;
    }
    if (this.tachografForm.value.predkosc) {
      whatToRead = whatToRead | 0b010000;
    }
    if (this.tachografForm.value.dane) {
      whatToRead = whatToRead | 0b100000;
    }
    
    if (this.tachograf.id) {
      const stan = {
        nazwa: this.tachografForm.value.nazwa,
        whatToRead: whatToRead,
        startTime: firestore.Timestamp.fromDate(this.tachografForm.controls['startCzas'].value),
        endTime: firestore.Timestamp.fromDate(this.tachografForm.controls['koniecCzas'].value)
      };
      this.tachografy.zapisz(this.tachograf, stan);
    } else {
      this.tachograf.nazwa = this.tachografForm.value.nazwa;
      this.tachograf.whatToRead = whatToRead;
      this.tachograf.startTime = firestore.Timestamp.fromDate(this.tachografForm.controls['startCzas'].value);
      this.tachograf.endTime = firestore.Timestamp.fromDate(this.tachografForm.controls['koniecCzas'].value);
      this.tachografy.dodaj(this.tachograf);
    }
    this.dialogRef.close();
  }
}
